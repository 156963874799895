import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Col, ControlLabel, FormGroup, Row } from "react-bootstrap";
import SettingsInput from "./SettingsInput";
import toastr from "toastr";
import { week } from "../../constants/time";
import WaitlistSettingsHour from "./WaitlistSettingsHour";
import { updateBusinessSettings } from "../../services/profile/profile.service";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { isBigAccount, trulieveCanUseSelfCheckin } from "../../configs/access";
import AddLocationForGoogleModal from "components/AddLocationForGoogleModal/AddLocationForGoogleModal";
import { max, set } from "date-fns";

const WaitlistSettings = ({ settings, business, businessId, plan, ...props }) => {
  const distances = [
    {
      text: "within 500 feet of your address",
      value: 152
    },
    {
      text: "within 1,000 feet of your address",
      value: 305
    },
    {
      text: "within 1,500 feet of your address",
      value: 457
    },
    {
      text: "within 1/2 mile of your address",
      value: 805
    },
    {
      text: "within 1 mile of your address",
      value: 1609
    },
    {
      text: "within 5 miles of your address",
      value: 8047
    }
  ];

  const maxPartySizes = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 }
  ];

  const { handleSubmit, errors } = useForm();
  const [linkCopied, setLinkCopied] = useState(false);
  const [qrCodeLoading, setQrCodeLoading] = useState(false);
  const [showGoogleLocationModal, setShowGoogleLocationModal] = useState({ show: false, type: null });
  const { account } = useSelector(state => state.account || {});

  const [form, setForm] = useState({
    selfCheckIn: false,
    hours: []
  });
  const [checkInLink, setCheckInLink] = useState("");

  useEffect(() => {
    if (settings) {
      setForm({
        selfCheckIn: plan === "free" ? false : settings?.selfCheckIn,
        geoFence: plan === "free" || plan === "premium" ? false : settings?.geoFence || false,
        geoFenceRadius: settings?.geoFenceRadius || 152,
        hours: settings?.waitlist?.hours || [],
        maxPartySize: settings?.waitlist?.maxPartySize || 8
      });
      setCheckInLink(`https://waitly.com/checkin/${businessId}`);
    }
    // eslint-disable-next-line
  }, [settings, businessId]);

  useEffect(() => {
    if (qrCodeLoading) {
      const canvas = document.getElementById("qr-code");
      const pngUrl = canvas.toDataURL("image/png");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr-code.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setQrCodeLoading(false);
    }
  }, [qrCodeLoading]);

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const toggleGeoFenceChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
    if (!business?.address && event.target.checked) {
      setShowGoogleLocationModal({ show: true, type: "geo-fence" });
    }
  };

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = async data => {
    await updateBusinessSettings(businessId, {
      ...{
        selfCheckIn: form.selfCheckIn,
        geoFence: form.geoFence,
        geoFenceRadius: parseInt(form.geoFenceRadius) || 152,
        waitlist: {
          maxPartySize: parseInt(form.maxPartySize) || 8,
          hours: form.hours
        }
      },
      ...data
    });
    toastr.success(`Settings updated`);
  };

  const downloadQR = () => {
    setQrCodeLoading(true);
  };

  const onCopyLink = () => {
    setLinkCopied(true);
    const el = document.createElement("textarea");
    el.value = checkInLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toastr.success(`Copied To Clipboard`);
  };

  const canUseSelfCheckIn = business => {
    if (isBigAccount({ id: business.accountId })) {
      return trulieveCanUseSelfCheckin(business);
    }
    return true;
  };

  const onValidChange = (day, time) => {
    let hours = [...form.hours];
    if (time.begin) {
      hours[week.indexOf(day)].begin = time.begin;
    }
    if (time.end) {
      hours[week.indexOf(day)].end = time.end;
    }
    if (time.closed !== undefined) {
      hours[week.indexOf(day)].closed = time.closed;
    }
    setForm({ ...form, hours: hours });
  };

  return (
    <>
      {showGoogleLocationModal.show && (
        <AddLocationForGoogleModal
          show={showGoogleLocationModal.show}
          type={showGoogleLocationModal.type}
          onHide={type => {
            if (type === "geo-fence") {
              setShowGoogleLocationModal(state => ({ ...state, show: false }));
              setForm({ ...form, geoFence: false });
              return;
            }
            setShowGoogleLocationModal(state => ({ ...state, show: false }));
            setForm({ ...form, geoFence: false });
          }}
          onSubmit={type => {
            if (type === "geo-fence") {
              setShowGoogleLocationModal(state => ({ ...state, show: false }));
            }
            const saveBtn = document.getElementById("waitlist-settings-save-btn");
            saveBtn.click();
          }}
          business={business}
        />
      )}
      <SettingsWrapper
        title={`Self Check-in ${props.businessName ? " - " + props.businessName : ""}`}
        handleSubmit={handleSubmit(onSubmit)}
        buttonStyles={{ maxWidth: 830, marginTop: 20 }}
        saveBtnId={"waitlist-settings-save-btn"}
      >
        <Row>
          <Col md={6}>
            <SettingsInput
              form={form}
              name={"selfCheckIn"}
              type={"switch"}
              enabled={plan !== "free"}
              errors={errors}
              value={form.selfCheckIn}
              label={"Self check-in"}
              subtitle={`When on, guests can check in from the link below.`}
              register={null}
              onChangeValue={toggleChecked}
            />
          </Col>
          {plan === "free" && (
            <div className="settings-subtitle self-check-wrapper">
              Self check is a Premium feature.
              <Link to="/admin/upgrade" style={{ fontWeight: "700" }}>
                Upgrade now
              </Link>
            </div>
          )}
        </Row>
        {canUseSelfCheckIn(business) && (
          <Row>
            <Col md={12}>
              <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
                <div className="settings-label-wrapper">
                  <ControlLabel style={{ color: !form.selfCheckIn && "#CAC9C9" }} className="settings-label">
                    Self check-in link
                  </ControlLabel>
                  <ControlLabel style={{ color: !form.selfCheckIn && "#CAC9C9" }} className="settings-subtitle">
                    Share this link or QR code with your guests to check themselves in
                  </ControlLabel>
                </div>
                <div className="flex">
                  <div className="settings-waitlist-link-wrapper">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        cursor: !form.selfCheckIn && "default",
                        pointerEvents: !form.selfCheckIn && "none",
                        color: !form.selfCheckIn && "#CAC9C9"
                      }}
                      href={checkInLink}
                    >
                      {checkInLink}
                    </a>
                  </div>
                  {!linkCopied && (
                    <i
                      onClick={onCopyLink}
                      style={{ color: !form.selfCheckIn && "#CAC9C9", pointerEvents: !form.selfCheckIn && "none" }}
                      className="mdi mdi-24px mdi-clipboard-text"
                    />
                  )}
                  {linkCopied && <i style={{ color: "green" }} className="mdi mdi-24px mdi-clipboard-check" />}
                </div>
                {form.selfCheckIn && plan !== "free" && (
                  <div className="qr-code-wrapper">
                    <QRCode
                      onClick={downloadQR}
                      id="qr-code"
                      style={{ display: qrCodeLoading && "none" }}
                      value={checkInLink}
                      size={qrCodeLoading ? 500 : 128}
                      level={"H"}
                      includeMargin={true}
                    />
                    {qrCodeLoading && (
                      <div className="loading-qr-code-wrapper">
                        <i className="mdi mdi-loading" />
                      </div>
                    )}
                    <div onClick={downloadQR}>
                      <i className="mdi mdi-download" />
                    </div>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={9}>
            <SettingsInput
              form={form}
              name={"maxPartySize"}
              type={"select"}
              enabled={form.selfCheckIn}
              value={form.maxPartySize}
              inputStyles={{ width: "100px" }}
              options={maxPartySizes}
              onChangeValue={onChangeFormField}
              errors={errors}
              subtitle={`Largest party that can be added with self check-in`}
              label={"Self check-in max party size"}
              register={null}
            />
          </Col>
        </Row>
        <Row>
          <Col md={11}>
            <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
              <div className="settings-label-wrapper">
                <ControlLabel style={{ color: !form.selfCheckIn && "#CAC9C9" }} className="settings-label">
                  Self check-in hours
                </ControlLabel>
                <ControlLabel style={{ color: !form.selfCheckIn && "#CAC9C9" }} className="settings-subtitle">
                  The hours that guests can add themselves to the waitlist.
                </ControlLabel>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {form.hours.map((hour, index) => (
            <WaitlistSettingsHour
              key={index}
              time={hour}
              value={!hour.closed}
              disabled={!form.selfCheckIn}
              day={week[index]}
              onValidChange={onValidChange}
            />
          ))}
        </Row>
        <Row>
          <Col md={6}>
            <SettingsInput
              form={form}
              name={"geoFence"}
              type={"switch"}
              enabled={plan !== "free" && plan !== "premium"}
              errors={errors}
              value={form.geoFence}
              label={"Geo-Fencing"}
              subtitle={`When on, guests can only use self check-in when close to your address.`}
              register={null}
              onChangeValue={toggleGeoFenceChecked}
            />
          </Col>
          {(plan === "free" || plan === "premium") && (
            <div className="settings-subtitle self-check-wrapper">
              Self check is a Pro feature.
              <Link to="/admin/upgrade" style={{ fontWeight: "700" }}>
                Upgrade now
              </Link>
            </div>
          )}
        </Row>
        <Row>
          <Col md={9}>
            <SettingsInput
              form={form}
              name={"geoFenceRadius"}
              type={"select"}
              enabled={form.geoFence}
              value={form.geoFenceRadius}
              options={distances}
              onChangeValue={onChangeFormField}
              errors={errors}
              subtitle={`How close guests need to be to check in`}
              label={"Geo-Fence Radius"}
              register={null}
            />
          </Col>
        </Row>
      </SettingsWrapper>
    </>
  );
};

export default WaitlistSettings;
